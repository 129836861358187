// @flow
import React from "react"

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {Container, Grid, TextField, Typography} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import useMediaQuery from '@material-ui/core/useMediaQuery';

type Props = {}

type State = {|
  balance: number,
  homeEquity: number,
  homeSaleYear: number,
  startingYear: number,
  yearsToProject: number,
  startingBalance: number,
  salary1: number,
  salary2: number,
  contRate1: number,
  contRate2: number,
  empContRate1: number,
  empContRate2: number,
  startingAge1: number,
  startingAge2: number,
  retAge1: number,
  retAge2: number,
  ssStartAge1: number,
  ssStartAge2: number,
  ssAmount1: number,
  ssAmount2: number,
  inflationRate: number,
  homeValue: number,
  mortgageBalance: number,
  homeAppreciationRate: number,
  mortgagePaymentYearly: number,
  returnRateAccumulationPhase: number,
  returnRateDrawPhase: number,
  currentSpend: number,
  additionalSpend: number,
  yearStats: Map<number, any>,
  addPerson2: boolean,
|}

const theme = createMuiTheme()

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize:                     '2.4rem',
  '@media (min-width:600px)':   {
    fontSize: '2.6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '6.0rem'
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize:                     '1.8rem',
  '@media (min-width:600px)':   {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem'
  },
}

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize:                     '1.4rem',
  '@media (min-width:600px)':   {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.0rem'
  },
}

function changeForRate(rate: number, original: number): number {
  return ((100 + rate) / 100 - 1) * original
}

function retirementContribution(salary, rate, empRate, inflationIndex) {
  const contribution = (rate + empRate) / 100 * salary
  return contribution * inflationIndex
}

function ssDraw(baseAmount: number, inflation: number): number {
  const yearlyBase = baseAmount * 12
  return yearlyBase + changeForRate(inflation, yearlyBase)
}

function MaybeMobileMessage() {
  const isMobile = useMediaQuery('(max-width: 375px)')

  return (
    <Grid container justify="center">
      {isMobile && <em align="center" style={{margin: 6}}>Best viewed in landscape on mobile</em>}
    </Grid>
  )

}
export default class Inputs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      balance:                     0,
      homeEquity:                  0,
      startingYear:                2020,
      homeSaleYear:                0,
      yearsToProject:              40,
      startingBalance:             100000,
      salary1:                     60000,
      salary2:                     60000,
      contRate1:                   10,
      contRate2:                   10,
      empContRate1:                4,
      empContRate2:                4,
      startingAge1:                40,
      startingAge2:                40,
      retAge1:                     67,
      retAge2:                     67,
      ssStartAge1:                 67,
      ssStartAge2:                 67,
      ssAmount1:                   1500,
      ssAmount2:                   1500,
      inflationRate:               2.0,
      homeValue:                   200000,
      mortgageBalance:             150000,
      homeAppreciationRate:        4,
      mortgagePaymentYearly:       14400,
      returnRateAccumulationPhase: 6.5,
      returnRateDrawPhase:         3.5,
      currentSpend:                45000,
      additionalSpend:             0,
      yearStats:                   new Map<number, any>(),
      addPerson2:                  false,
    }
    this.recompute = this.recompute.bind(this)
  }

  handleAdjustmentChange(year: number, event: SyntheticInputEvent<>) {
    const adjustment = event.target.value
    const yearStats = new Map(this.state.yearStats)
    const stat = yearStats.get(year)
    stat.adjustment = adjustment
    yearStats.set(year, stat)
    this.setState({yearStats})
  }

  handleNumericPropertyChange(key: string, event: SyntheticInputEvent<>) {
    const value = event.target.value
    this.setState({
      [key]: Number(value)
    })
  }

  handleTogglePerson2 = () => {
    this.setState({addPerson2: !this.state.addPerson2})
  }

  recompute() {
    const {
      startingYear,
      yearsToProject,
      startingBalance,
      startingAge1,
      startingAge2,
      salary1,
      salary2,
      contRate1,
      contRate2,
      empContRate1,
      empContRate2,
      retAge1,
      retAge2,
      ssStartAge1,
      ssStartAge2,
      ssAmount1,
      ssAmount2,
      inflationRate,
      homeValue,
      homeSaleYear,
      mortgageBalance,
      homeAppreciationRate,
      mortgagePaymentYearly,
      returnRateAccumulationPhase,
      returnRateDrawPhase,
      currentSpend,
      additionalSpend,
    } = this.state

    const yearStats = new Map()
    let balance = startingBalance
    let ownsHome = true
    let currentMortgageBalance = mortgageBalance
    let currentHomeValue = homeValue
    let homeEquity = homeValue - mortgageBalance
    // Assume that accumulation rate gives way to draw rate at ret age 1.
    const lastAccumulationYear = startingYear + (retAge1 - startingAge1 - 4)
    let age1 = startingAge1
    let age2 = startingAge2

    let ssInflation = 1
    const endingYear = startingYear + yearsToProject
    let totalInflation = 1
    for (let year = startingYear; year < endingYear; year++) {
      const adjustment = Number((this.state.yearStats.get(year) || {adjustment: 0}).adjustment)
      const beginningYearBalance = balance
      let spend = 0
      totalInflation = totalInflation * (100 + inflationRate) / 100

      let contributions = 0

      // Add contributions (retirement savings or SS) for each person
      if (age1 < retAge1) {
        contributions += retirementContribution(salary1, contRate1, empContRate1, totalInflation)
      }

      if (age1 >= ssStartAge1) {
        contributions += ssDraw(ssAmount1, ssInflation)
        ssInflation = ssInflation * (100 + inflationRate) / 100
      }

      if (this.state.addPerson2) {
        if (age2 < retAge2) {
          contributions += retirementContribution(salary2, contRate2, empContRate2, totalInflation)
        }

        if (age2 >= ssStartAge2) {
          contributions += ssDraw(ssAmount2, ssInflation)
        }
      }

      // Interest rate changes close to retirement.
      const interestRate = year <= lastAccumulationYear ? returnRateAccumulationPhase : returnRateDrawPhase

      // Remove draws if at or past retirement.
      if (age1 >= retAge1 && age2 >= retAge2) {
        let draw = (currentSpend + additionalSpend) * inflationRate
        spend += draw
      }

      const interestEarned = changeForRate(interestRate, (2 * balance + contributions - spend + adjustment) / 2)

      // Accumulate home equity.

      if (ownsHome) {
        if (currentMortgageBalance > 0) {
          currentMortgageBalance = Math.max(currentMortgageBalance - mortgagePaymentYearly, 0)
        }

        currentHomeValue = currentHomeValue + changeForRate(homeAppreciationRate, currentHomeValue)
        homeEquity = currentHomeValue - currentMortgageBalance
      }

      if (year === homeSaleYear) {
        contributions += homeEquity
        homeEquity = 0
        ownsHome = false
      }

      balance += (contributions - spend + interestEarned + adjustment)

      age1 += 1
      age2 += 1
      yearStats.set(year, {
        year,
        beginningBalance: new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 6}).format(beginningYearBalance),
        contributions:    new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 5}).format(contributions),
        spend:            new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 6}).format(spend),
        effectiveSpend:   new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 6}).format(spend / inflationRate),
        interest:         new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 5}).format(interestEarned),
        endingBalance:    new Intl.NumberFormat('us-EN', {maximumSignificantDigits: 6}).format(balance),
        adjustment,
      })
    }

    this.setState({
      balance, homeEquity, yearStats
    })
  }

  renderYearStats() {
    const {yearStats} = this.state

    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{fontWeight: "bold"}}>
              <TableCell>Year</TableCell>
              <TableCell align="center">Beginning balance</TableCell>
              <TableCell align="center">Contributions from earnings & social security</TableCell>
              <TableCell align="center">Adjustments (+ for contributions, - for expenses)</TableCell>
              <TableCell align="center">Spend (inflated dollars)</TableCell>
              <TableCell align="center">Effective spend</TableCell>
              <TableCell align="center">Interest</TableCell>
              <TableCell align="center">Ending balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(yearStats)
              .reverse()
              .map(([year, stat]) => (
                <TableRow key={year}>
                  <TableCell component="th" scope="row">
                    {year}
                  </TableCell>
                  <TableCell padding="none" align="right">{stat.beginningBalance}</TableCell>
                  <TableCell padding="none" align="right">{stat.contributions}</TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      step={1}
                      fullWidth
                      label="Adjustment"
                      value={stat.adjustment}
                      variant="filled"
                      onChange={evt => this.handleAdjustmentChange(year, evt)}
                    />
                  </TableCell>
                  <TableCell padding="none" align="right">{stat.spend}</TableCell>
                  <TableCell padding="none" align="right">{stat.effectiveSpend}</TableCell>
                  <TableCell padding="none" align="right">{stat.interest}</TableCell>
                  <TableCell align="right">{stat.endingBalance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    const {
      startingYear,
      homeSaleYear,
      yearsToProject,
      startingBalance,
      startingAge1,
      startingAge2,
      salary1,
      salary2,
      contRate1,
      contRate2,
      empContRate1,
      empContRate2,
      retAge1,
      retAge2,
      ssStartAge1,
      ssStartAge2,
      ssAmount1,
      ssAmount2,
      inflationRate,
      homeValue,
      mortgageBalance,
      homeAppreciationRate,
      mortgagePaymentYearly,
      returnRateAccumulationPhase,
      returnRateDrawPhase,
      currentSpend,
      additionalSpend,
    } = this.state


    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Typography variant="h1" align="center">Retirement calculator *</Typography>
          <List title="Instructions">
            <ListItem>How to use</ListItem>
            <ListItem>1. Fill out "Overall" below for your current savings and home equity.</ListItem>
            <ListItem>2. Add your salary and retirement contribution details under "Person 1." </ListItem>
            <ListItem>3. Add a partner's income by checking "Add partner income" and filling that section.</ListItem>
            <ListItem>4. Click "CALCULATE" to estimate your retirement account balance by year for the projection
              range.</ListItem>
            <ListItem>5. Adjust your contribution rate, spending, etc and recalculate to estimate the impact on your yearly retirement balance.</ListItem>
          </List>
          <Typography>
            Note:
          </Typography>
          <Typography>
            This calculator will inflate your contributions from earnings and social security at the rate of inflation that you provide.
            If you choose to provide a date for "sell house", the estimated equity will be counted as a contribution and increase your balance in that year.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container justify="center">
                <Typography variant="h2">Overall</Typography>
                <TextField type="number" step={0.1} fullWidth label="Starting year" value={startingYear}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("startingYear", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Years to project" value={yearsToProject}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("yearsToProject", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Starting balance" value={startingBalance}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("startingBalance", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Current spend" value={currentSpend}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("currentSpend", evt)}/>
                <TextField type="number" step={0.1} fullWidth label={`Additional spend (${startingYear} dollars)`}
                           value={additionalSpend} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("additionalSpend", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Inflation rate" value={inflationRate}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("inflationRate", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Home value" value={homeValue} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("homeValue", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Mortgage balance" value={mortgageBalance}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("mortgageBalance", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Home appreciation rate"
                           value={homeAppreciationRate}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("homeAppreciationRate", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Mortgage payment yearly"
                           value={mortgagePaymentYearly}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("mortgagePaymentYearly", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Return rate during accumulation"
                           value={returnRateAccumulationPhase}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("returnRateAccumulationPhase", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Return rate during draw phase"
                           value={returnRateDrawPhase} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("returnRateDrawPhase", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Sell home in year (leave blank to skip)"
                           value={homeSaleYear} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("homeSaleYear", evt)}/>

              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container justify="center">
                <Typography variant="h2">My income</Typography>
                <TextField type="number" step={0.1} fullWidth label="Salary 1" value={salary1} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("salary1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Contribution rate (eg 5 for 5%)" value={contRate1}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("contRate1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Employer contribution rate (eg 5 for 5%)"
                           value={empContRate1} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("empContRate1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Starting age" value={startingAge1} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("startingAge1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Retirement age" value={retAge1} variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("retAge1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Social security start age" value={ssStartAge1}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("ssStartAge1", evt)}/>
                <TextField type="number" step={0.1} fullWidth label="Social security amount monthly" value={ssAmount1}
                           variant="filled"
                           onChange={evt => this.handleNumericPropertyChange("ssAmount1", evt)}/>
                {!this.state.addPerson2 && (
                  <Button
                    style={{marginTop: 6}}
                    onClick={this.handleTogglePerson2}
                    name="addPerson2"
                    variant="contained">
                    Add partner income</Button>
                )}
              </Grid>
            </Grid>
            {this.state.addPerson2 && (
              <Grid item xs={12} md={4}>
                <Grid container justify="center">
                  <Typography variant="h2">Partner income</Typography>
                  <TextField type="number" step={0.1} fullWidth label="Salary 2" value={salary2} variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("salary2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Contribution rate (eg 5 for 5%)"
                             value={contRate2}
                             variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("contRate2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Employer contribution rate (eg 5 for 5%)"
                             value={empContRate2} variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("empContRate2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Starting age" value={startingAge2}
                             variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("startingAge2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Retirement age" value={retAge2} variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("retAge2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Social security start age" value={ssStartAge2}
                             variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("ssStartAge2", evt)}/>
                  <TextField type="number" step={0.1} fullWidth label="Social security amount monthly" value={ssAmount2}
                             variant="filled"
                             onChange={evt => this.handleNumericPropertyChange("ssAmount2", evt)}/>
                    <Button
                      style={{marginTop: 6}}
                      onClick={this.handleTogglePerson2}
                      name="addPerson2"
                      variant="contained"
                      color="secondary">
                      Remove partner income</Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid container justify="center">
                <Button style={{marginTop: 12, marginBottom: 12}} onClick={this.recompute} variant="contained"
                        color="primary">Calculate</Button>
              </Grid>
              <Typography variant="h2">{yearsToProject} years</Typography>
              <MaybeMobileMessage/>
              <Grid item xs={12}>
                {this.renderYearStats()}
              </Grid>
            </Grid>
          </Grid>
          <Typography style={{margin: 6}}>* this is not financial advice. Please discuss your retirement planning with
            your own professional advisor.</Typography>
        </Container>
      </ThemeProvider>
    )
  }
}
