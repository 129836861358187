import React from 'react';
import './App.css';

import Assumptions from "./assumptions.jsx"

function App() {
  return <Assumptions/>;
}

export default App;
